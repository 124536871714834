.app {
  /* text-align: center; */
  margin: auto;
  width: var(--site-width);
  position: relative;
  border: 1px solid;
  padding: 1rem;
  box-sizing: border-box;
}

/* .error:focus{
  outline-color: darkred;
} */
label i {
  float: right;
}
label.required::after {
  content: "*";
  color: var(--alert-color);
}

.row_radio input {
  width: 2rem;
  display: inline;
  accent-color: var(--header-color);
  border-radius: 50%;
}
.row_radio input:focus,
.row_radio input:hover {
  outline: none;
  box-shadow: 0 0 0 1px var(--focus-outline);
}
.row_radio label {
  white-space: nowrap;
  padding: 0.25rem 2rem 0.5rem 0.5rem;
  flex-grow: 1;
}
.validation {
  background-repeat: no-repeat;
  background-position: right 0.2rem;
  background-size: 1.2rem;
}

.validation:valid {
  border-color: var(--good-color);
  /* outline-style: solid;
  outline-width: .1rem;
  outline-offset: -.2rem; */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiM4ZmUxYTI7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOiM4ZmUxYTI7c3Ryb2tlLXdpZHRoOjQzLjAzNjtzdHJva2UtbGluZWNhcDpzcXVhcmU7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1kYXNoYXJyYXk6bm9uZTtzdHJva2Utb3BhY2l0eToxO3BhaW50LW9yZGVyOnN0cm9rZSBmaWxsIG1hcmtlcnMiIGQ9Ik0gNDE4LjQ4OTc1LDgzLjg4NjE0MiBDIDE0Mi43MjU2Miw0MjAuMzAyNzggNDIwLjAyNTA2LDgyLjc3NTEwOSAxNDEuOTIwNjcsNDIwLjIxODM0IGwgLTQ4LjA2MTM4NSwwLjcwNTA0IC03MC4yNzc0MzIsLTk3LjE5NjI5IDM3LjUxOTcwOCwtMzMuOTY4OTEgNjUuNjM0NzI5LDk1Ljg4NTYxIEMgMzY0LjQ4NDMzLDgzLjUxMjU3NCAxMjUuOTM0NjIsMzg0LjAyODMxIDM2NC41MzkzOCw4My44ODYxNDIgWiIgaWQ9ImJpZ2NoZWNrIiAvPg0KPC9zdmc+IA==);
}

.validation:invalid {
  border-color: var(--alert-color);
  /* outline-style: solid;
  outline-width: .1rem;
  outline-offset: -.2rem; */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiNlNzg2ODA7c3Ryb2tlOiNlNzg2ODA7c3Ryb2tlLXdpZHRoOjQzLjAzNjtzdHJva2UtZGFzaGFycmF5Om5vbmU7c3Ryb2tlLWxpbmVjYXA6c3F1YXJlO3BhaW50LW9yZGVyOnN0cm9rZSBmaWxsIG1hcmtlcnM7c3Ryb2tlLWxpbmVqb2luOnJvdW5kIiBkID0gIk0gNDE4LjU1MzQ4LDg0LjYxNTgxOCAyOTAuNzYzMjYsMjUxLjIxMDM3IDQxOC4zMDQ4Myw0MjEuNjUzMDYgSCAzNjEuMzcxMDUgTCAyNjAuNDMxNzUsMjgyLjg5OTQ4IDE1Ny4wMDYyMSw0MjEuNjUzMDYgSCAxMDMuMzA0NSBMIDIzMi4zMzc3OSwyNTMuMjQ3NiAxMDYuMjg3OTMsODQuNjE1ODE4IGggNTYuNjg1MTQgTCAyNjIuNjY5MjksMjIxLjU1ODQ4IDM2NC42MDMxMSw4NC42MTU4MTggWiIgaWQ9ImJpZ3giIC8+DQo8L3N2Zz4=);
}
.input_line {
  border-radius: 0.25rem 0.25rem 0 0;
}

/* line level button */
.button_line {
  border-radius: 0 0 0.25rem 0.25rem;
  white-space: nowrap;
}
/* line level button */
.button_stack {
  margin-top: 1rem;
  white-space: nowrap;
}

/* Loading bar area */
/* inspired/stolen from https://codepen.io/hopearmstrong/pen/VNYoNq*/
#loading-bar {
  background-color: #fff;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  aspect-ratio: 1 / 1;
  padding: 2rem;
}
#loading-bar-left {
  color: #ccc;
  animation: loading-bar-morph 1s linear 0.2s infinite;
  transform-origin: center;
}
#loading-bar-middle {
  animation: loading-bar-morph 1s linear 0.3s infinite;
  transform-origin: center;
}
#loading-bar-right {
  animation: loading-bar-morph 1s linear 0.4s infinite;
  transform-origin: center;
}

@keyframes loading-bar-morph {
  0% {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(0.3);
  }
  50% {
    transform: scaleY(0.7);
  }
  75% {
    transform: scaleY(0.15);
  }
}

@media (min-width: 768px) {
  .input_line {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  .button_line {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}
